$( document ).on('turbolinks:load', function() {
  manageOrthoticWidth();
  manageHeelCupDepth();
  manageHeelCupDepthCustom();
  manageCastArchFillLeft();
  manageCastArchFillRight();
  manageKirbySkiveOther();
  manageRearfootPostingNeutral();
  manageReafootPostingPerpendicular();
  manageReafootPostingUpost();

  manageDgrTypeR();
  manageDgrTypeL();

  manageRpR();
  manageRpL();

  managePRIntrinsic();
  managePRExtrinsic();

  manageKirbySkive();

  manageMainMetCHB();
  manageSubMetCHB();

  manageOrderFPPostVertical();

  manageFPCheck();
  manageFPNumber();
  manageFPSelect();
});

function manageFPCheck() {
  $('body').on('change', '.fp-check', function(e) {
    $($(e.target).data('id')).val($(this).is(':checked'));
  });

  return false;
}

function manageFPNumber() {
  $('body').on('keyup change', '.fp-number', function(e) {
    $($(e.target).data('id')).val(e.target.value);
  });

  return false;
}

function manageFPSelect() {
  $('body').on('change', '.fp-select', function(e) {
    $($(e.target).data('id')).val(e.target.value);
  });

  return false;
}

function manageOrderFPPostVertical() {
  $('body').on('change', '#order_fp_post_vertical', function(e) {
    if ($(this).is(':checked')) {

      $('#order_fp_intrinsic').val(false);
      $('#order_fp_extrinsic').val(false);
      $('#order_fp_l').val('');
      $('#order_fp_r').val('');
      $('#order_fp_dgr_type_l').val('');
      $('#order_fp_dgr_type_r').val('');

      $('.fp_wrapper input[type="checkbox"]').prop('checked', false);
      $('.fp_wrapper input').val('');
      $('.fp_wrapper select').val('');
      $('.fp_wrapper input').attr('disabled', true);
      $('.fp_wrapper select').attr('disabled', true);
      $(this).prop('checked', true);
    } else {
      $(this).prop('checked', false);
      $('.fp_wrapper input').attr('disabled', false);
    }
  });
  return false;
}

function  manageKirbySkiveOther() {
  $('body').on('keyup change', '.kirby_skive_other', function (e) {
    var wrapper = $(this).data('wrapper');
    if ($(this).val() == '0' || $(this).val() == '') {
      $(wrapper + ' input').removeAttr('disabled');
      $(wrapper + ' select').removeAttr('disabled');
    } else {
      $(wrapper + ' input').prop('checked', false);
      $(wrapper + ' select').val('');
      $(wrapper + ' select').prop('disabled', true);
      $(wrapper + ' input').prop('disabled', true);
    }
  });

  return false;
}

function manageKirbySkive() {
  $('body').on('change', 'select[id^="order_kirby_skive_"]', function (e) {
    var kirbySkive = $(this).data('kirbySkive');
    $(kirbySkive).prop('checked', $(this).val() != '');
  });

  return false;
}

function manageOrthoticWidth() {
  $('body').on('click', '.orthotic_width input[type="checkbox"]', function (e) {
    if ($(this).is(':checked')) {
      $('.orthotic_width input[type="checkbox"]').prop('checked', false);
      $(this).prop('checked', true);
    }
  });

  return false;
}

function manageHeelCupDepth() {
  $('body').on('click', '.hcd_wrapper input[type="checkbox"]', function (e) {
    if ($(this).is(':checked')) {
      $('.hcd_wrapper input[type="checkbox"]').prop('checked', false);
      $(this).prop('checked', true);
      if ($(this).attr('id') !== 'order_heel_cup_depth_custom_read') {
        $('#order_heel_cup_depth_custom_number_read').val('');
        $('#order_heel_cup_depth').val($(this).val());
      } else {
        $('#order_heel_cup_depth_custom_number_read').val(0);
        $('#order_heel_cup_depth').val(0);
      }
    } else {
      $('#order_heel_cup_depth').val('');
      $('#order_heel_cup_depth_custom_number_read').val('');
    }
  });

  return false;
}

function manageHeelCupDepthCustom() {
  $('body').on('keyup change', '.hcd_wrapper input[type="number"]', function (e) {
    $('.hcd_wrapper input[type="checkbox"]').prop('checked', false);
    $('#order_heel_cup_depth_custom_read').prop('checked', true);
    $('#order_heel_cup_depth').val($(this).val());
  });

  return false;
}

function manageCastArchFillLeft() {
  $('body').on('click', '.cast_arch_fill_left input[type="checkbox"]', function (e) {
    if ($(this).is(':checked')) {
      $('.cast_arch_fill_left input[type="checkbox"]').prop('checked', false);
      $(this).prop('checked', true);
      $('#order_cast_arch_fill_left').val($(this).val());
    } else {
      $('#order_cast_arch_fill_left').val('');
    }
  });
  return false;
}

function manageCastArchFillRight() {
  $('body').on('click', '.cast_arch_fill_right input[type="checkbox"]', function (e) {
    if ($(this).is(':checked')) {
      $('.cast_arch_fill_right input[type="checkbox"]').prop('checked', false);
      $(this).prop('checked', true);
      $('#order_cast_arch_fill_right').val($(this).val());
    } else {
      $('#order_cast_arch_fill_right').val('');
    }
  });
  return false;
}

function manageRearfootPostingNeutral() {
  $('body').on('click', '#rp_neutral', function (e) {
    if ($(this).is(':checked')) {
      $('.rearfoot_posting input[type="checkbox"]').prop('checked', false);
      $('.neutral_off input[type="checkbox"]').attr("disabled", true);
      $(this).prop('checked', true);
      $('#order_rearfoot_posting').val($(this).val());

      $('.neutral_off input[type="number"]').attr("disabled", true);
      $('.neutral_off select').attr("disabled", true);

      $('#order_rp_intrinsic').val('false');
      $('#order_rp_extrinsic').val('false');

      $('#order_rp_dgr_type_l').val('');
      $('#order_rp_dgr_type_r').val('');

    } else {
      $('#order_rearfoot_posting').val('');
      $('.neutral_off select').removeAttr("disabled");
    }
    $('.neutral_off input[type="number"]').val('');
    $('.neutral_off select').val('');

    $('#order_rp_l').val('');
    $('#order_rp_r').val('');
    $('#order_rp_dgr_type_l').val('');
    $('#order_rp_dgr_type_r').val('');
  });

  return false;
}

function manageReafootPostingPerpendicular() {
  $('body').on('click', '#rp_post_perpendicular', function (e) {
    if ($(this).is(':checked')) {
      $('.rearfoot_posting input[type="checkbox"]').prop('checked', false);
      $(this).prop('checked', true);
      $('#order_rearfoot_posting').val($(this).val());

      $('.neutral_off input[type="number"]').attr("disabled", true);
      $('.neutral_off select').attr("disabled", true);
      $('.neutral_off input[type="checkbox"]').removeAttr("disabled");

    } else {
      $('#order_rearfoot_posting').val('');
    }
    $('.neutral_off select').val('');
    $('.neutral_off input[type="number"]').val('');
    $('.neutral_off input[type="checkbox"]').prop('checked', false);
    $('#order_rp_intrinsic').val('false');
    $('#order_rp_extrinsic').val('false');

    $('#order_rp_l').val('');
    $('#order_rp_r').val('');
    $('#order_rp_dgr_type_l').val('');
    $('#order_rp_dgr_type_r').val('');
  });

  return false;
}

function manageReafootPostingUpost() {
  $('body').on('click', '#rp_u-post', function (e) {
    if ($(this).is(':checked')) {
      $('.rearfoot_posting input[type="checkbox"]').prop('checked', false);
      $(this).prop('checked', true);
      $('#order_rearfoot_posting').val($(this).val());

      $('.neutral_off select').removeAttr("disabled");
      $('.neutral_off input[type="number"]').removeAttr("disabled");

      $('#order_rp_intrinsic_read').attr("disabled", true);
      $('#order_rp_extrinsic_read').attr('disabled', false);
      $('#order_rp_intrinsic').val('false');
    } else {
      $('#order_rearfoot_posting').val('');
      $('.neutral_off input').removeAttr("disabled");
      $('.neutral_off input[type="number"]').val('');
      $('.neutral_off select').val('');
    }

    $('#order_rp_extrinsic_read').prop('checked', false);
    $('#order_rp_extrinsic').val('false');
  });

  return false;
}

function manageDgrTypeR() {
  $('body').on('change', '#order_rp_dgr_type_r_read', function (e) {
    $('#order_rp_dgr_type_r').val($(this).val());
  });

  return false;
}

function manageDgrTypeL() {
  $('body').on('change', '#order_rp_dgr_type_l_read', function (e) {
    $('#order_rp_dgr_type_l').val($(this).val());
  });

  return false;
}

function manageRpR() {
  $('body').on('change', '#order_rp_r_read', function (e) {
    $('#order_rp_r').val($(this).val());
  });
  return false;
}

function manageRpL() {
  $('body').on('change', '#order_rp_l_read', function (e) {
    $('#order_rp_l').val($(this).val());
  });
  return false;
}

function managePRIntrinsic() {
  $('body').on('click', '#order_rp_intrinsic_read', function (e) {
    $('#order_rp_intrinsic').val($(this).is(':checked'));
  });
  return false;
}

function managePRExtrinsic() {
  $('body').on('click', '#order_rp_extrinsic_read', function (e) {
    $('#order_rp_extrinsic').val($(this).is(':checked'));
  });
  return false;
}


function manageMainMetCHB() {
  $('body').on('click', '.met_wrapper input[type="checkbox"].main_met', function (e) {
    if ($(this).is(':checked')) {
      $('.met_wrapper input[type="checkbox"]').prop('checked', false);
      $(this).prop('checked', true);
    } else {
      $(this).closest('.met_wrapper').find('input[type="checkbox"].sub_met').prop('checked', false);
    }
  });

  return false;
}

function manageSubMetCHB(){
  $('body').on('click', '.met_wrapper input[type="checkbox"].sub_met', function (e) {
    if ($(this).is(':checked')) {
      $.each($(this).data('groups'), function(i, item) {
        $(item + ' input[type="checkbox"]').prop('checked', false);
      });

      $(this).prop('checked', true);
      $(this).closest('.met_wrapper').find('input[type="checkbox"].main_met').prop('checked', true);

    } else {
      $(this).closest('.met_wrapper').find('input[type="checkbox"].sub_met').prop('checked', false);
    }
  });
}