$( document ).on('turbolinks:load', function() {

  $('body').on('click', '#order_1 input[type="checkbox"]', function (e) {
    var oppositeCheckBox = $(this).data('opposite');

    $(oppositeCheckBox).prop('checked', false);

    $('#order_gender').val($(this).val());
  });

});