$( document ).on('turbolinks:load', function() {

  handleNextOrderButton();
  handleViceVersaCleanUp();
});

function handleNextOrderButton() {
  $('body').on('click', 'input[type="submit"].next_order', function(e){
    e.preventDefault();
    $('#order_step').remove();
    $(this).closest('form').submit();
  });
}

function handleViceVersaCleanUp() {
  $('body').on('change', 'input[type="number"].vice_versa', function(e){
    e.preventDefault();

    var antagonistId = $(this).data('vice-versa-id');
    $(antagonistId).val('');
  });
}

