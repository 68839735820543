$( document ).on('turbolinks:load', function() {

  manageTopCovers();
  manageTopCovers2();
  manageTopCoversMaterial();

  manageMainCheckBox();
  manageSubCheckBox();
  manageMainInputCheckBox();
  manageSubInputs();
  manageExtensionsLength();
  manageExtensionsThickness();
  manageExtensionsMaterial();
  manageARMaterial();
  manageARMFeet();

  manageExtToToesRead();
  manageSaLateralFlange();
});

function manageSaLateralFlange() {
  $('body').on('change', 'input[type=checkbox].sa_flange', function(e) {
    $($(e.target).data('id')).prop('checked', false);
  });

  return false;
}

function manageTopCovers() {
  $('body').on('click', '.top_covers input[type="checkbox"]', function (e) {
    if ($(this).is(':checked')) {
      $('.top_covers input[type="checkbox"]').prop('checked', false);
      $(this).prop('checked', true);
      $('#order_length_top_covers').val($(this).val());

      if ($(this).attr('id') === 'no_top_cover'){
        $('.no_top_cover input[type="checkbox"]').prop('checked', false);
        $('.no_top_cover input').attr('disabled', true);
        $('.tc_material_wrapper_hidden').val(false);
        $('#order_tc_material').val(false);

        $('.thickness_of_top_cover.main_wrapper input[type="checkbox"]').val('');
        $('.totc_hidden').val('');

        $('.ext_length_wrapper input[type="checkbox"]').prop('checked', false);
        $('.ext_length_wrapper input[type="checkbox"]').attr('disabled', true);
        $('.ext_children_wrapper input[type="checkbox"]').prop('checked', false);
        $('.ext_children_wrapper input[type="checkbox"]').attr('disabled', true);
        $('.extensions_hidden').val(false);
        $('.extensions_children_hidden').val(false);
      }
    } else {
      if ($('.tc_material input:not("checked")') == 3) {
        $('.main-cb, .sub-cb').removeAttr('disabled');
      }
      $('#order_length_top_covers').val('');
      if ($(this).attr('id') === 'no_top_cover'){
        $('.no_top_cover input').removeAttr('disabled');
      }
    }
  });

  return false;
}

function manageTopCovers2() {
  $('body').on('click', '#to_mets, #to_sulcus, #to_toes', function (e) {
    if ($(this).is(':checked')) {
      $('.top_covers_inline input[type="checkbox"]').prop('checked', false);
      $(this).prop('checked', true);
      $('#order_length_top_covers').val($(this).val());

      if ($('.tc_material input:disabled').length > 0) {
        $('.no_top_cover input').removeAttr('disabled');
      } else {
        if ($('.main-cb input:checked') == 0) {
          $('.thickness_of_top_cover input').removeAttr('disabled');
        }
      }

      if ($(this).val() === 'to_sulcus') {
        $('.ext_length_wrapper input[type="checkbox"]').removeAttr('disabled');
        $('.ext_children_wrapper input[type="checkbox"]').removeAttr('disabled');

        $('#order_ext_to_toes').val('');
        $('#order_ext_to_toes_read').prop('checked', false);
        $('#order_ext_to_toes_read').attr('disabled', true);
      }

      if ($(this).val() === 'to_mets') {
        $('.ext_length_wrapper input[type="checkbox"]').prop('checked', false);
        $('.ext_children_wrapper input[type="checkbox"]').prop('checked', false);
        $('.ext_length_wrapper input[type="checkbox"]').attr('disabled', true);
        $('.ext_children_wrapper input[type="checkbox"]').attr('disabled', true);

        $('#order_ext_to_toes').val('');
        $('#order_extensions_thickness').val('');
        $('#order_extensions_material').val('');
      }

    } else {
      $('#order_length_top_covers').val('');
      if ($(this).val() === 'to_sulcus') {
        $('#order_ext_to_toes_read').removeAttr('disabled');
        $('#order_ext_to_sulcus_read').prop('checked', false);
        $('#order_ext_to_sulcus').val(false);
      }

      if ($(this).val() === 'to_mets') {
        $('.ext_length_wrapper input[type="checkbox"]').removeAttr('disabled');
        $('.ext_children_wrapper input[type="checkbox"]').removeAttr('disabled');
      }
    }

    if ($(this).val() === 'to_toes') {
      $('.ext_length_wrapper input[type="checkbox"]').removeAttr('disabled');
      $('.ext_children_wrapper input[type="checkbox"]').removeAttr('disabled');
    }
  });

  return false;
}

function manageExtToToesRead() {
  $('body').on('click', '#order_ext_to_toes_read', function (e) {
    $('#order_ext_to_toes').val($(this).is(':checked'));
  });

  return false;
}

function manageTopCoversMaterial() {
  $('body').on('click', '.tc_material input[type="checkbox"]', function (e) {
    if ($(this).is(':checked')) {
      $('.tc_material input[type="checkbox"]').prop('checked', false);
      $(this).prop('checked', true);
      $('#order_tc_material').val($(this).val());
      disableCheckboxes('.thickness_of_top_cover', '.totc_hidden');

      $('.tc_material_wrapper input').prop('checked', false);
      $('.tc_material_wrapper input').attr('disabled', true);
    } else {
      enableCheckboxes('.thickness_of_top_cover');
      $('#order_tc_material').val('');
      $('.tc_material_wrapper input').removeAttr('disabled');
    }
    $('.tc_material_wrapper_hidden').val(false);
  });
  return false;
}

// it is can be a common js pattern for checkboxes
function manageMainCheckBox () {
  $('body').on('click', 'input[type="checkbox"].main-cb', function (e) {
    if ($(this).is(':checked')) {
      disableCheckboxes('.thickness_of_top_cover', '.totc_hidden');
      var mainWrapper = $(this).closest('.main_wrapper');
      $(mainWrapper).find('input[type="checkbox"]').prop('checked', false);
      $(this).prop('checked', true);
    } else {
      enableCheckboxes('.thickness_of_top_cover');
      var subWrapper = $(this).closest('.sub_wrapper');
      $(subWrapper).find('input[type="checkbox"]').prop('checked', false);
      enableCheckboxes('.thickness_of_top_cover');
    }
  });
  return false;
}

function disableCheckboxes(visibleSelector, hiddenSelector) {
  $(visibleSelector + ' input[type="checkbox"]').val('');
  $(visibleSelector + ' input[type="checkbox"]').attr('disabled', true);
  $(hiddenSelector).val('');

  return false;
}

function enableCheckboxes(visibleSelector) {
  $(visibleSelector + ' input[type="checkbox"]').removeAttr('disabled');

  return false;
}

function manageSubCheckBox() {
  $('body').on('click', 'input[type="checkbox"].sub-cb', function (e) {
    var subWrapper = $(this).closest('.sub_wrapper');
    if ($(this).is(':checked')) {
      disableCheckboxes('.thickness_of_top_cover', '.totc_hidden');

      var mainWrapper = $(this).closest('.main_wrapper');
      $(mainWrapper).find('input[type="checkbox"]').prop('checked', false);

      $(subWrapper).find('input[type="checkbox"].main-cb').prop('checked', true);
      $(subWrapper).find('input[type="checkbox"].sub-cb').prop('checked', false);
      $(this).prop('checked', true);
    } else {
      $(subWrapper).find('input[type="checkbox"].main-cb').prop('checked', false);
    }
  });
  return false;
}

// it is can be a common js pattern for inputs
function manageMainInputCheckBox() {
  $('body').on('click', 'input[type="checkbox"].main-in', function (e) {
    var mainWrapper = $(this).closest('.main_wrapper');
    if ($(this).is(':checked')) {
      $(mainWrapper).find('input[type="checkbox"]').prop('checked', false);
      $(this).prop('checked', true);
    }
    $(mainWrapper).find('input[type="number"]').not(this).filter(function() {
      return this.value;
    }).val('');
  });
  return false;
}

function manageSubInputs() {
  $('body').on('change', 'input[type="checkbox"].sub-in', function (e) {
    var subWrapper = $(this).closest('.sub_wrapper');
    if ($(this).is(':checked')) {
      var mainWrapper = $(this).closest('.main_wrapper');
      $(mainWrapper).find('input[type="checkbox"]').prop('checked', false);
      $(this).prop('checked', true);
      $(subWrapper).find('input[type="checkbox"].main-in').prop('checked', true);
    } else {
      $(subWrapper).find('input[type="checkbox"].main-in').prop('checked', false);
    }
  });
  return false;
}

function manageExtensionsLength() {
  $('body').on('click', '.ext_length_wrapper input[type="checkbox"]', function (e) {
    var state = $(this).is(':checked');
    var hiddenInput = $(this).data('hiddenInput');
    $('.extensions_hidden').val(false);
    $(hiddenInput).val(state);

    $('.ext_length_wrapper input[type="checkbox"]').prop('checked', false); // to clear ext header checkbox
    $('.ext_children_wrapper input[type="checkbox"]').prop('checked', false); // to clear ext children checkbox

    $('#order_extensions_thickness').val('');
    $('#order_extensions_material').val('');
    $(this).prop('checked', state);
  })
}

// dry
function manageExtensionsThickness() {
  $('body').on('click', '.extensions_thickness_wrapper input[type="checkbox"]', function (e) {
    if ($(this).is(':checked')) {
      $('.extensions_thickness_wrapper input[type="checkbox"]').prop('checked', false);
      $(this).prop('checked', true);
      $('#order_extensions_thickness').val($(this).val());
    } else {
      $('#order_extensions_thickness').val('');
    }
  })
}

function manageExtensionsMaterial() {
  $('body').on('click', '.extensions_material_wrapper input[type="checkbox"]', function (e) {
    if ($(this).is(':checked')) {
      $('.extensions_material_wrapper input[type="checkbox"]').prop('checked', false);
      $(this).prop('checked', true);
      $('#order_extensions_material').val($(this).val());
    } else {
      $('#order_extensions_material').val('');
    }
  })
}

function manageARMaterial() {
  $('body').on('click', '.ar_material_wrapper input[type="checkbox"]', function (e) {
    if ($(this).is(':checked')) {
      $('.ar_material_wrapper input[type="checkbox"]').prop('checked', false);
      $(this).prop('checked', true);
      $('#order_arch_reinforcement_material').val($(this).val());
    } else {
      $('#order_arch_reinforcement_material').val('');
    }
  })
}

function manageARMFeet() {
  $('body').on('click', '.arch-main input[type="checkbox"]', function (e) {
    if ($(this).is(':checked')) {
      $('.ar_material_wrapper input').attr('disabled', false);
      $(this).prop('checked', true);
    } else {

      if (document.querySelectorAll(`.arch-main input:checked`).length == 0) {
        $('#order_arch_reinforcement_material').val('');
        $('.ar_material_wrapper input').prop('checked', false);
        $('.ar_material_wrapper input').attr('disabled', true);
      }
    }
  })
}

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}
// dry