// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

import "bootstrap";
import '../stylesheets/application'

import './sorts'
import './admins/orders'
import './common/clients'
import './common/orders'
import './common/order_1'
import './common/order_2'
import './common/order_3'
import './common/order_4'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("../stimulus/controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();
})

$( document ).on('turbolinks:load', function() {
  if ($('.alert.alert-success').id != 'alert') {
    return setTimeout(function(){
      $('.alert.alert-success, .alert.alert-danger').fadeTo(500,0,function(){
        $(this).addClass('hide');
      });
    },5000);
  }
});