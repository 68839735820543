$( document ).on('turbolinks:load', function() {

  handleInputsShipping();
  handleBillingLabel();
});

function handleInputsShipping() {
  $('body').on('keyup', 'input[type="text"].shipping', function(e){
    if ($('#user_billing_info_label').is(':checked')) {
      setUpBillingInputs(this);
    }
  });
}

function handleBillingLabel() {
  $('body').on('click', '#user_billing_info_label', function(e){
    if ($(this).is(':checked')) {
      $.each($('input[type="text"].shipping'), function (i, item) {
        setUpBillingInputs(item);
      })
    }
  });
}

function setUpBillingInputs(input) {
    var billingInputId = $(input).data('id');

    $(billingInputId).val($(input).val());

    return false;
}