// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

$( document ).on('turbolinks:load', function() {
  updateOrderStatus();
  sendOrderFilterForm();
  sendOrderStatusFilterForm();
  sortBy();
});

function sortBy() {
  $('body').on('change', '#admin_order_sort select', function (e) {

    $('#filter_sort').val($(e.target).val());
    $('#order_filters').submit();

    return false;
  });
}

function updateOrderStatus() {

  $('body').on('change', 'select.select-order', function(e){
    var target = e.target;

    $.ajax({
      method: 'PATCH',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      url: '/admins/orders/' + $(target).data('order-id') + '/update_status.json',
      data: { order: { status: $(target).val() } },
      success: function (response) {
        if ($(".alert.alert-danger").length > 0) {
          $("#alert").removeClass('alert-danger');
          $("#alert").addClass('alert-success');
        }
        setAlert('.alert.alert-success', response['notice']);
      },
      error: function (response) {
        if ($(".alert.alert-success").length > 0) {
          $("#alert").removeClass('alert-success');
          $("#alert").addClass('alert-danger');
        }
        setAlert('.alert.alert-danger', response['notice'], response['errors']);
      }
    });

    return false;
  })
}

function setAlert(cssClass, notice, content = false) {
  $(cssClass).text(notice);
  $(cssClass).removeClass('hide');

  if (content) {
    var ul = $('<ul></ul>');
    $.each(content, function(key, val) {
      $('<li>' + key + ': ' + val + '</li>').appendTo(ul);
    });
    ul.appendTo('#alert');
  }

  return setTimeout(function(){
    $(cssClass).fadeTo(500,0,function(){
      $(this).addClass('hide');
    });
  },5000);
}

function sendOrderFilterForm() {
  $('body').on('change', 'input[type="checkbox"].client_filter', function(e) {
    var checked = $('.order_filters input[type="checkbox"]:checked');

    var checked_val = $.map(checked, function( item, i ) {
      return $(item).data('client-id');
    });

    $('#filter_client_id').val(checked_val);
    $('#order_filters').submit();
    return false;
  });
}

function sendOrderStatusFilterForm() {
  $('body').on('change', 'input[type="checkbox"].status_filter', function(e) {
    var checked = $('.status.order_filters input[type="checkbox"]:checked');

    var checked_val = $.map(checked, function( item, i ) {
      return $(item).data('status');
    });

    $('#filter_status').val(checked_val);
    $('#order_filters').submit();
    return false;
  });
}