$( document ).on('turbolinks:load', function() {
  step21ManageCheckboxClicking();
  step22ManageCheckboxClicking();
});

function step21ManageCheckboxClicking() {
  $('body').on('click', 'input[name="order[orthotic_type_read]"]', function (e) {
    if ($(this).is(':checked')) {
      $('#order_orthotic_type').val($(this).val());
      $('input[name*="order[orthotic_type_read]"]').prop('checked', false);
      $(this).prop('checked', true);
    } else {
      $('#order_orthotic_type').val('');
    }
    $('input[name="order[orthotic_flexibility_read]"]').prop('checked', false);
    $('#order_orthotic_flexibility').val('');
  });

  return false;
}

function step22ManageCheckboxClicking() {
  $('body').on('click', 'input[name="order[orthotic_flexibility_read]"]', function (e) {
    var wrapper = $(this).closest('.form-group.row');
    if ($(this).is(':checked')) {
      $('#order_orthotic_flexibility').val($(this).val());
      $('input[name="order[orthotic_flexibility_read]"]').prop('checked', false);
      $(this).prop('checked', true);

      $('input[name*="order[orthotic_type_read]"]').prop('checked', false);
      $(wrapper).find('input[name="order[orthotic_type_read]"]').prop('checked', true);
      var ossTypeReadVal =  $(wrapper).find('input[name="order[orthotic_type_read]"]').val();
      $('#order_orthotic_type').val(ossTypeReadVal);
    } else {
      $(wrapper).find('input[name="order[orthotic_type_read]"]').prop('checked', false);
      $('#order_orthotic_type').val('');
      $('#order_orthotic_flexibility').val('');
    }
  });
  return false;
}
