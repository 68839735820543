import { Controller } from "stimulus";
import axios from "axios";
import autocomplete from "autocomplete.js";

export default class extends Controller {
  static targets = ["field"];

  source() {
    // const url = this.data.get("url");
    return (query, callback) => {
      axios.get('/admins/orders.json', { params: { query } }).then((response) => {
        callback(response.data);
      });
    };
  }

  connect() {
    this.ac = autocomplete(this.fieldTarget, { hint: false }, [
      {
        source: this.source(),
        debounce: 200,
        templates: {
          suggestion: function (suggestion) {
            return suggestion.name;
          },
        },
      },
    ]).on("autocomplete:selected", (event, suggestion, dataset, context) => {
      this.ac.autocomplete.setVal(suggestion.name);

      axios.get('/admins/clients/' + suggestion.id + '.json').then((response) => {
        console.log(response.data);
        document.getElementsByName("order[office_address]")[0].value = response.data.shipping_address;
        document.getElementsByName("order[telephone]")[0].value = response.data.phone;
        document.getElementsByName("order[city]")[0].value = response.data.shipping_city;
        document.getElementsByName("order[province]")[0].value = response.data.shipping_province;
      });

    });
  }
}